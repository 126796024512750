<template>
<Transition>
    <div v-if="consentRead && !consentSet" class="cookie-consent">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="description" v-html="$t('COOKIE.DESCRIPTION')" />
        <div class="buttons">
            <button class="primary" v-on:click="onAccept">{{ $t('COOKIE.ACCEPT_ALL') }}</button>
            <button class="outline small" v-on:click="onReject">{{ $t('COOKIE.REJECT_ALL') }}</button>
        </div>
    </div>
</Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type ConsentValue = 'granted' | 'denied'
interface Consent {
    adStorage: ConsentValue,
    adUserData: ConsentValue,
    adPersonalization: ConsentValue,
    analyticsStorage: ConsentValue
    personalizationStorage: ConsentValue,
    functionalityStorage: ConsentValue,
    securityStorage: ConsentValue

}

export default defineComponent({
    data() {
        return {
            consent: <Consent> {
                adStorage: 'denied',
                adUserData: 'denied',
                adPersonalization: 'denied',
                analyticsStorage: 'denied',
                personalizationStorage: 'granted',
                functionalityStorage: 'granted',
                securityStorage: 'granted'
            },
            consentRead: <boolean>false,
            consentSet: <boolean>false
        }
    },
    mounted() {
        if (import.meta.client) {
            this.readConsent()
        }
        return false
    },
    methods: {
        readConsent() {
            const dateString = localStorage.getItem('consent_exp')
            if (dateString) {
                const exp = new Date(dateString)
                if (new Date() > exp) {
                    localStorage.removeItem('consent_exp')
                    localStorage.removeItem('consent')
                }
                this.consentSet = true
            }
            const consentString = localStorage.getItem('consent')
            if (consentString) {
                this.consent = JSON.parse(consentString)
            }
            this.consentRead = true
            this.updateConsent()
        },
        setConsent(val: Consent) {
            localStorage.setItem('consent', JSON.stringify(val))
            const expDate = new Date()
            expDate.setDate(expDate.getDate() + 182) // ~6 months
            localStorage.setItem('consent_exp', expDate.toUTCString())
            this.readConsent()
        },
        onAccept() {
            this.consent.adStorage = 'granted'
            this.consent.adUserData = 'granted'
            this.consent.adPersonalization = 'granted'
            this.consent.analyticsStorage = 'granted'
            this.setConsent(this.consent)
        },
        onReject() {
            this.consent.adStorage = 'denied'
            this.consent.adUserData = 'denied'
            this.consent.adPersonalization = 'denied'
            this.consent.analyticsStorage = 'granted'
            this.setConsent(this.consent)
        },
        updateConsent() {
            this.$gtm.push({
                consent: this.consent
            })
            this.$gtm.trackEvent({
                event: 'ConsentUpdated'
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.cookie-consent {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--surface-900);
    border: 2px solid var(--primary-300);
    border-radius: 20px;
    z-index: 1000000;
    width: 60%;
    padding: 20px;
    margin: 10px;
    max-width: 500px;

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @include mq-small-and-less {
        margin: 0px;
        width: 100%;
        max-width: unset;
        border-radius: 20px 20px 0 0;
    }
}

.v-enter-active {
  transition: bottom 0.3s ease-out;
}

.v-leave-active {
    transition: bottom 0.3s ease-in;
}

.v-enter-from,
.v-leave-to {
  bottom: -400px;
}

</style>
